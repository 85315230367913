<template>
  <div class="container">
    <div class="mb-custom">
      <router-link
        v-if="contentFor === 'Photos'"
        :to="'/DashboardOutlet/' + `${$route.params.outlet_id}`"
      >
        <nav
          class="navbar m-auto navbar-Container navbar-light backtheme py-3 d-flex justify-content-center headerstyle"
        >
          <div class="col-md-1 col-1 px-0">
            <img class="img-back" src="../assets/icon-back.png" alt />
          </div>
          <div class="m-auto">
            <h1 class="font20 fontipad mb-0 text-white text-center">
              {{ title }}
            </h1>
          </div>
        </nav>
      </router-link>
    </div>
    <div class="mb-custom" v-if="contentFor === 'DashboardOutlet'">
      <nav
        class="navbar m-auto navbar-Container navbar-light backtheme py-3 d-flex justify-content-center headerstyle"
      >
        <div class="m-auto">
          <h1 class="font20 fontipad mb-0 text-white text-center">
            {{ title }}
          </h1>
        </div>
      </nav>
    </div>
    <div class="mb-custom">
      <router-link
        v-if="contentFor === 'foto'"
        v-bind:to="'/DashboardOutlet/' + `${$route.params.outlet_id}`"
      >
        <nav
          class="navbar m-auto navbar-Container navbar-light backtheme py-3 d-flex justify-content-center footerstyle"
        >
          <div class="col-md-1 col-1 px-0">
            <img class="img-back" src="../assets/icon-back.png" alt />
          </div>
          <div class="col-md-11 col-11">
            <h1 class="font20 fontipad mb-0 text-white text-center">
              {{ title }}
            </h1>
          </div>
        </nav>
      </router-link>
    </div>
    <div class="mb-customSlider" v-if="contentFor === 'slider'">
      <nav
        class="navbar m-auto navbar-Container navbar-light backtheme py-3 d-flex justify-content-center footerstyle"
      >
        <div class="col-md-11 col-11">
          <h1 class="font17 fontipad mb-0 text-white text-center">
            {{ title }}
          </h1>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "contentFor"],
};
</script>

<style scoped>
.backtheme {
  background: #5c27aa;
}

.headerstyle {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 1030;
}

.navbar-Container {
  max-width: 750px;
}

.img-back {
  width: 40%;
}

@media screen and (max-width: 1000px) {
  .mb-custom {
    margin-bottom: 5rem;
  }

  .footerstyle {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1030;
  }

  .font17 {
    font-size: 17px;
  }

  .font20 {
    font-size: 20px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .navbar-Container {
      max-width: 100vw;
    }

    .fontipad {
      font-size: 30px;
    }
  }
}
</style>

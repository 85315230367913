<template>
  <div class="container">
    <div
      class="col-12 col-md-12 m-0 px-0 shadow-item"
      @click="takeFoto(notif.outlet_id)"
    >
      <div
        class="text-left border rounded d-flex flex-row align-items-center my-3 py-3"
      >
        <div class="col-3 col-md-3">
          <img
            class="w-auto mx-auto d-flex justify-content-center"
            src="../assets/store-icon.png"
            alt
          />
        </div>
        <div class="d-flex justify-content-between pr-1 pl-2">
          <div
            class="d-block align-items-center text-center col-md-12 col-12 px-0"
          >
            <h5
              class="font14 caption4 px-0 py-2 m-0 font-weight-normal text-purple text-left"
            >
              {{ notif.outlet_id }}
            </h5>
            <p
              :class="{
                'text-center font-12 py-1 m-0 px-3 font-weight-normal text-white rounded-pill badge bg-danger float-left':
                  notif.status === 'Foto Ditolak',
                'text-center font-12 py-1 m-0 px-3 font-weight-normal text-white rounded-pill badge bg-success float-left':
                  notif.status === 'Foto Diterima',
              }"
            >
              {{ notif.status }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  props: ["outlet", "notif", "isLoading"],
  methods: {
    takeFoto(outlet_id) {
      console.log(outlet_id);
      this.$router.push(`/Photos/${this.$route.params.outlet_id}`);
      $("#todo" + outlet_id).modal("hide");
    },
  },
};
</script>

<style scoped>
.iconSpinner {
  top: 50vh;
  z-index: 120;
}
.divSpinner {
  z-index: 100;
  right: 0;
  left: 0;
  top: 0vh;
  background-color: #b684ff;
  min-width: 360px;
  max-width: 750px;
  margin: 0 auto !important;
  min-height: 100vh;
  backdrop-filter: blur(2px);
  opacity: 0.3;
}
.text-purple {
  color: #3d0293 !important;
}
.shadow-item {
  box-shadow: 0 0.1rem 0.8rem rgba(150, 142, 255, 0.15) !important;
}

.imagecustom {
  width: 6%;
}

.text-purple {
  color: #4e37b2;
}

.shadow-item {
  box-shadow: 0 0.1rem 0.8rem rgba(150, 142, 255, 0.15) !important;
}

.btn-foto {
  background: #21bcaa;
  color: #ffffff;
}

.btn-add {
  background: #ff7750;
  color: #ffffff;
}

.bg-modal {
  background: #5c27aa;
  color: #ffffff;
}

.imagecustom {
  width: 6%;
}

@media screen and (max-width: 1000px) {
  .font14 {
    font-size: 14px;
  }

  .w-icon {
    width: 55%;
  }

  .imagecustom {
    background-size: cover;
    height: 33px;
    width: 9%;
  }
}
</style>

<template>
  <div>
    <Header title="Notification" :contentFor="'foto'" />
    <ListOutlet :contentFor="'Notification'" />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import ListOutlet from "../components/ListOutlet.vue";
export default {
  components: {
    Header,
    ListOutlet,
  },
};
</script>

<style>
</style>
<template>
  <div class="container">
    <div v-if="contentFor === 'DashboardOutlet'">
      <div class="row d-flex justify-content-between mt-5">
        <div class="col-12">
          <h4 class="font-weight-bold text-purple font14 text-left my-0 py-0">
            Daftar Outlet
          </h4>
          <p class="text-purple text-left font12px">
            Pilih outlet untuk dimonitoring.
          </p>
        </div>
      </div>
      <div
        class="col-12 col-md-12 m-0 px-0 shadow-item"
        v-for="(all, index) in listOutlet.data"
        :key="index"
      >
        <div
          class="text-left border rounded d-flex flex-row align-items-center my-3 py-3 px-3"
        >
          <div class="col-4 col-md-4">
            <img class="imagecustom" src="../assets/store-icon.png" alt />
          </div>

          <div class="align-items-center text-center col-md-4 col-4">
            <a
              class="font14 caption4 p-0 m-0 font-weight-normal text-purple"
              @click="upload(all.id)"
            >
              {{ all.bulan }}
            </a>
          </div>
          <div class="col-4 col-md-4 text-center">
            <img class="w-icon" :src="require('../assets/' + all.icon)" alt />
          </div>
        </div>
      </div>
    </div>
    <div v-if="contentFor === 'Notification'">
      <TitleHeader
        title="Daftar Notifikasi Anda"
        caption="Berikut adalah notification status list outlet"
        class="my-4"
      />
      <ItemOutlet v-for="notif in notifs.data" :key="notif.id" :notif="notif" />
    </div>
  </div>
</template>
<script>
import ItemOutlet from "../components/ItemOutlet.vue";
import TitleHeader from "../components/TitleHeader.vue";
export default {
  props: ["listOutlet", "contentFor"],
  data() {
    return { timestamp: "" };
  },
  components: { TitleHeader, ItemOutlet },
  methods: {
    getNow: function () {
      const today = new Date();
      const date = today.getMonth() + 1;
      const dateTime = date + " ";
      this.timestamp = dateTime;
    },
    upload(id) {
      if (id == this.timestamp) {
        this.$router.push(`/Photos/${this.$route.params.outlet_id}`);
      } else {
        alert("Belum bulan untuk upload");
        this.$router.push(`/DashboardOutlet/${this.$route.params.outlet_id}`);
      }
    },
  },
  computed: {
    notifs() {
      return this.$store.state.notifs;
    },
  },
  created() {
    setInterval(this.getNow);
  },
  mounted() {
    this.$store.dispatch("getNotif", {
      token: localStorage.token,
    });
  },
};
</script>
<style scoped>
.bg-purple {
  background-color: #4e37b2;
  color: #ffffff;
  box-shadow: 2px 4px 12px rgba(78, 55, 178, 0.35);
}

.bg-modal {
  background: #5c27aa;
  color: #ffffff;
}

.text-purple {
  color: #4e37b2;
}

.font12 {
  font-size: 12px;
}

.shadow-item {
  box-shadow: 0 0.1rem 0.8rem rgba(150, 142, 255, 0.15) !important;
}

.btn-foto {
  background: #21bcaa;
  color: #ffffff;
}

.btn-add {
  background: #ff7750;
  color: #ffffff;
}

.btn-menu {
  color: #4e37b2;
  background-color: #e5e0ff;
}

.btn-menu:hover {
  background-color: #4e37b2;
  border-color: #4e37b2;
  color: white;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: none;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: none;
}

.font12 {
  font-size: 12px;
}

.imagecustom {
  background-size: cover;
  height: 50px;
  width: auto;
}

@media (max-width: 600px) {
  .content {
    /* This is the magic bit for Firefox */
    overflow-x: scroll;
    scrollbar-width: none;
    max-width: 100%;
  }

  .content::-webkit-scrollbar {
    /* This is the magic bit for WebKit */
    display: none;
  }

  .font14 {
    font-size: 14px;
  }

  .font12px {
    font-size: 12px;
  }

  .font11 {
    font-size: 11px;
  }

  .w-icon {
    width: 20px;
  }

  .dropdown-menu {
    min-width: 7rem;
  }

  .font12 {
    font-size: 12px;
  }

  .imagecustom {
    background-size: cover;
    height: 33px;
    width: auto;
  }
}
</style>

<template>
  <div class="">
    <LoginPage @getLogin="getLogin" />
  </div>
</template>

<script>
import LoginPage from "@/components/LoginPage.vue";
import axios from "axios";
export default {
  components: { LoginPage },
  methods: {
    async getLogin(username, password) {
      const data = {
        username: username,
        password: password,
      };
      try {
        let res = await axios({
          method: "post",
          url: `${process.env.VUE_APP_URL}auth/login`,
          data: data,
        });
        if (res.status === 200) {
          console.log(res.data.data.token);
          localStorage.token = res.data.data.token;
          this.$router.push(`DashboardOutlet/${username}`);
        } else {
          alert("Username atau password anda salah");
          window.location.reload();
        }
        return res.data;
      } catch (error) {
        console.error(error, "error");
        console.log(error.response);
        alert(error.response.data.data);
        window.location.reload();
      }
    },
  },
};
</script>

<style scoped>
</style>
<template>
  <div>
    <Header title="Dashboard Outlet" :contentFor="'DashboardOutlet'" />
    <Slider :slider="Slider" />
    <ListOutlet
      :listOutlet="listOutlet"
      :contentFor="'DashboardOutlet'"
      class="mb-custom"
    />
  </div>
</template>

<script>
import ListOutlet from "../components/ListOutlet.vue";
import Header from "../components/Header.vue";
import Slider from "../components/Slider.vue";
import axios from "axios";
export default {
  components: { Header, Slider, ListOutlet },
  data() {
    return {
      Slider: {
        data: [],
      },
      listOutlet: {
        data: [],
      },
    };
  },
  mounted() {
    this.getSlider();
    this.getOutlet();
  },
  methods: {
    getSlider() {
      axios
        .get(`${process.env.VUE_APP_URL}banner`, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((res) => (this.Slider = res.data))
        .catch((err) => console.log(err));
    },
    async getOutlet() {
      const url = `${process.env.VUE_APP_URL}outlet/image`;
      const option = { headers: { Authorization: localStorage.token } };
      const get = await axios.get(url, option);
      this.listOutlet = get.data.data;
      // axios
      //   .get(`${process.env.VUE_APP_URL}outlet/image`, {
      //     headers: {
      //       Authorization: localStorage.token,
      //     },
      //   })
      //   .then((res) => {
      //     console.log(res.data.data.data, "ressss");
      //     this.listOutlet = res.data.data;
      //   })

      //   .catch((error) => {
      //     console.error(error);
      //   });
    },
  },
};
</script>

<style scoped>
.mb-custom {
  margin-bottom: 5rem;
}
</style>
import axios from "axios";

export function getImage({ commit }, { token }) {
  axios
    .get(`${process.env.VUE_APP_URL}outlet/image/`, {
      headers: { authorization: token },
    })
    .then((response) => {
      commit("GETIMAGE", response.data.data.data);
    });
}

export function getNotif({ commit }, { token }) {
  axios
    .get(`${process.env.VUE_APP_URL}notification/`, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      commit("SET_NOTIF", response.data.data);
    });
}

<template>
  <div class="col-md-12 col-12 p-0">
    <div
      class="mx-4 rounded-custom"
      v-if="slider.data.length > 0"
      style="background-color: white"
    >
      <VueSlickCarousel :arrows="true" :dots="true" v-bind="settings">
        <div
          v-for="(list, index) in slider.data"
          :key="index"
          class="radius table-bordered"
        >
          <div class="d-flex justify-content-center">
            <img
              @click="details(list.id)"
              v-bind:src="list.foto"
              class="radius-img"
            />
          </div>

          <div class="row justify-content-center d-flex mt-3">
            <div class="col px-4">
              <h4 class="font-weight-bold font-12 text-left font13 my-0 py-0">
                {{ list.judul }}
              </h4>
              <p class="font-12 text-left font10 text-black-50">
                {{ list.deskripsi }}
              </p>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  components: {
    VueSlickCarousel,
  },
  name: "Slider",
  props: {
    slider: Object,
  },
  data() {
    return {
      settings: {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 4000,
        cssEase: "linear",
      },
    };
  },
  methods: {
    details(id) {
      this.$router.push(`/Slider/` + `${this.$route.params.outlet_id}/` + id);
    },
  },
};
</script>

<style scoped>
.rounded-custom {
  border-radius: 30px;
}

/deep/.slick-next {
  right: 6px;
}

/deep/.slick-prev {
  left: 6px;
  z-index: 1;
  top: 19vh;
}

.radius-img {
  border-radius: 18px 18px 0px 0px;
}
.table-bordered {
  border: 1px solid #d3d3d3 !important;
  border-radius: 18px 18px 18px 18px;
}

.icon {
  width: 50% !important;
  z-index: 10;
  position: relative;
}
@media screen and (max-width: 1000px) {
  .font-12 {
    font-size: 12px;
  }
  .font16 {
    font-size: 16px;
  }
  .font14 {
    font-size: 14px;
  }
  .font13 {
    font-size: 13px;
  }
}
</style>

<template>
  <div class="container position-fixed" style="left: 0; right: 0">
    <div class="row">
      <div class="col-12 bg-login col-md-12">
        <div class="text-white">
          <div class="pt-4">
            <img data-aos="fade-down" src="../assets/camera-logo.png" alt="" />
            <h1 data-aos="fade-right">Aplikasi Foto Display</h1>
            <p data-aos="fade-left">
              Aplikasi untuk foto display rak <br />
              yang ada di outlet setiap bulan.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        data-aos="fade-up"
        data-aos-duration="2000"
        class="container p-0"
        style="position: fixed; right: 0; left: 0; bottom: 0"
      >
        <div class="card">
          <div class="card-body">
            <h1 class="card-title text-violet m-0">Selamat Datang,</h1>
            <p class="card-text text-violet">
              Silahkan Login untuk melanjutkan
            </p>
            <div class="login-content">
              <form>
                <div class="input-div m-0">
                  <div class="d-flex justify-content-center align-items-center">
                    <img class="height-icon" src="../assets/user-login.png" />
                  </div>
                  <div class="div">
                    <input
                      v-model="username"
                      placeholder="Username"
                      type="text"
                      class="input"
                    />
                  </div>
                </div>
                <div class="input-div">
                  <div class="d-flex justify-content-center align-items-center">
                    <img
                      class="height-icon"
                      src="../assets/user-password.png"
                    />
                  </div>
                  <div class="div">
                    <input
                      v-model="password"
                      placeholder="Password"
                      type="password"
                      class="input"
                    />
                  </div>
                </div>
                <button
                  @click="getLogin"
                  type="button"
                  class="btn text-white m-0"
                >
                  Masuk
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    getLogin() {
      this.$emit("getLogin", this.username, this.password);
    },
  },
};
</script>

<style scoped>
.text-violet {
  text-align: left;
  color: #5a3ea4;
}

h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 27px;
}

p {
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.02em;
}

.card {
  border-radius: none;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  height: auto;
}

.login-content .input-div {
  position: relative;
  display: grid;
  grid-template-columns: 7% 93%;
  margin: 25px 0;
  padding: 5px 0;
  border-bottom: 2px solid #d9d9d9;
}

::placeholder {
  color: rgba(78, 55, 178, 0.5);
}

.bg-login {
  background: #5c27aa no-repeat center center;
  height: 100vh;
  border: 1px solid #5c27aa;
  width: 100px;
}

.input-div > div {
  position: relative;
  height: 45px;
}

.input-div > div > input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: none;
  padding: 0.5rem 0.7rem;
  font-size: 1.2rem;
  color: #555;
  font-family: "poppins", sans-serif;
}

.btn {
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 15px;
  background-image: linear-gradient(to right, #4e37b2, #4e37b2, #4e37b2);
  font-size: 1.2rem;
  color: #fff;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin: 1rem 0;
  cursor: pointer;
  box-shadow: 2px 4px 12px rgba(78, 55, 178, 0.35);
}

@media (max-width: 600px) {
  h1 {
    font-size: 22px;
    line-height: 27px;
  }

  .login-content .input-div {
    padding: 0px 0;
    grid-template-columns: 15% 93%;
  }

  .bg-login {
    background: url("../assets/bg-login.png") no-repeat center center fixed;
    height: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  p {
    padding: 0;
    font-size: 14px;
    line-height: 26px;
  }

  .height-icon {
    width: auto;
    height: 20px;
  }

  .bg-login img {
    width: 6rem;
  }
}
</style>

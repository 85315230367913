<template>
  <div class="container mx-0">
    <div class="row d-flex justify-content-between mt-1 mb-3">
      <div class="d-flex flex-row">
        <div class="d-flex flex-column col-12 col-md-12">
          <TitleHeader
            caption="Silahkan klik untuk melihat detail foto"
            title="List Foto display rak"
          />
        </div>
      </div>
    </div>
    <div class="row pt-2 px-2">
      <div class="col-md-4 col-4 px-1" v-for="item in image" :key="item.id">
        <div class="pb-3">
          <div class="px-0" v-if="item.foto != null">
            <expandable-image :src="item.foto" alt="" />
          </div>
          <div class="px-0" v-if="item.foto == null">
            <img class="w-empty" src="../assets/empty-pic.png" alt="" />
          </div>
          <p
            class="text-center box-date pt-1 px-1 font-12 mb-0 py-0 text-purple"
          >
            {{ item.bulan }}
          </p>

          <p
            :class="[
              'text-center font-weight-bold box-date px-1 py-1 font-10 mb-0 py-0 text-purple',
              { textdanger: item.status === 'Belum Foto' },
            ]"
          >
            {{ item.status }}
          </p>
          <p
            class="text-center box-date px-1 pb-1 font-10 mb-0 py-0 text-purple"
          >
            {{ item.upload }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleHeader from "./TitleHeader";

export default {
  components: { TitleHeader },
  props: ["image"],
};
</script>

<style scoped>
.text-purple {
  color: #4e37b2;
}

.textdanger {
  color: red;
}

/deep/ .expandable-image img {
  height: 120px;
  max-width: 100vw;
  object-fit: cover;
}

.bg-button {
  background-color: #4e37b2;
}

.bg-purple {
  background-color: #4e37b2;
  color: #ffffff;
  box-shadow: 2px 4px 12px rgba(78, 55, 178, 0.35);
}

.box-date {
  background-color: #e5e0ff;
}

.p-button {
  padding: 0.6rem 3rem !important;
}

.w-empty {
  width: 30%;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .w-empty {
    width: 40%;
  }

  .p-button {
    padding: 0.3rem 1rem !important;
  }

  /deep/ .expandable-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .font-10 {
    font-size: 10px;
  }

  .font-12 {
    font-size: 12px;
  }

  .font-14 {
    font-size: 14px;
  }

  .font-18 {
    font-size: 18px;
  }

  .font-14 {
    font-size: 14px;
  }
}
</style>
